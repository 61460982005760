<template>
  <div id="idea-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/ideas" class="btn btn-secondary mb-5 mr-3">Zurück zur Übersicht</router-link>
    <button @click="saveIdea" class="btn btn-primary mb-5">Idee speichern</button>

    <div v-if="step === 0" class="card">
      <div class="card-header">
        <h3>Großartig, eine neue Idee!</h3>
      </div>
      <div class="card-body">
        <p>
          Bevor du sie vergisst, trag' schnell ein worum es geht. <br>
          Wie immer: Du kannst den Titel später noch bearbeiten, falls du die Idee anders definieren willst.
        </p>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label>Gib der Idee einen Titel</label>
              <input v-model="newIdea.title" class="form-control" placeholder="Titel deiner Idee..." type="text">
            </div>

            <div class="form-group">
              <label>Grundgedanke der Idee:</label>
              <textarea v-model="newIdea.description" class="form-control" placeholder="Wenn wir..."></textarea>
            </div>

            <div class="form-group">
              <label>Du kannst auch ein Foto oder eine Skizze hochladen:</label>
              <input class="form-control-file" type="file" @change="uploadImage($event)">
            </div>
          </div>
          <div class="col-sm-6">
            <label>Welche Seite betrifft deine Idee:</label>
            <input v-model="search" class="form-control mb-3" placeholder="Nodes suchen..." type="text">

            <div class="border rounded px-3 pt-3">
              <NodeSelector :nodes="nodes"
                            :selected-node-url="newIdea.nodeUrl"
                            :exclude-items="['all']"
                            node-size="col-9 col-lg-7"
                            wrapper-classes="flex-nowrap scroll-container-h"
                            @selectNode="newIdea.nodeUrl = $event.url"
              ></NodeSelector>
            </div>
          </div>
          <div class="col-sm-6">
            <label>Gehört die Idee zu der aktuellen Theorie?</label>
            <select v-model="newIdea.theoryId" class="form-control mb-3">
              <option disabled>Theorie wählen</option>
              <option value="">Keine Theorie</option>
              <option v-for="(theory, index) in project.theories"
                      :value="theory.id"
                      :selected="newIdea.theoryId === theory.id"
                      :key="index"
              >
                {{ theory.title }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-6">
            <button class="btn btn-secondary btn-block" @click="saveIdea">Idee speichern und zur Übersicht</button>
          </div>
          <div class="col-sm-6">
            <button class="btn btn-primary btn-block" @click="step++">Idee weiter ausführen</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="step === 1" class="card">
      <div class="card-header">
        <h3>Deine Idee</h3>
      </div>
      <div class="card-body">
        <h4>Jetzt ist der richtige Zeitpunkt, deiner Idee eine Geschichte zu geben</h4>
        <div class="form-group">
          <label>Nochmal die Grundidee:</label>
          <textarea v-model="newIdea.description" class="form-control"></textarea>
        </div>

        <div class="form-group">
          <label>Mit einem "Slogan" wird deine Idee noch realer.</label>
          <input v-model="newIdea.lead" class="form-control" placeholder="Der Slogan für die Idee..." type="text">
        </div>

        <hr>

        <h4>Führe deine Idee jetzt genauer aus.</h4>
        <p>
          Eine Best Practice ist, deine Idee gleich mit einem Nutzerproblem zu verknüpfen und daraus eine Hypothese zu formulieren,
          so kannst du den Hintergedanken der Idee besser abstrahieren und später später besser herausfinden, ob dieser Grundgedanke
          für deine Nutzer wertvoll war und es sich lohnt weiter in diese Richtung zu optimieren.
        </p>

        <div class="row mt-4">
          <div class="col-md-6">
            <h5>Das Nutzerproblem</h5>
            <div class="form-group">
              <label>Was ist das Nutzerproblem, auf dem deine Idee basiert?</label>
              <input v-model="newIdea.userProblem" class="form-control" placeholder="Nutzer können/finden/erreichen/etc nicht X..." type="text">
              <p class="mt-2 small">
                Hypothesen sind immer in der gleichen Form aufgebaut. "Wenn" ... ",dann" ... ", weil" ...
              </p>
            </div>
            <div class="form-group">
              <label>Finding verknüpfen:</label>
              <ul class="list-unstyled">
                <li v-for="(finding, index) in findings" :key="index">
                  {{ finding.title }}
                  <i @click="removeFinding(index)" class="fal fa-minus-circle text-danger float-right"></i>
                </li>
              </ul>
              <select @change="addFinding($event)" class="form-control">
                <option selected>Finding auswählen</option>
                <option v-for="(newFinding, index) in newFindings" :value="newFinding.id" :key="index">
                  {{ newFinding.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <h5>Deine Hypothese</h5>
            <div class="row align-items-stretch">
              <div class="col-sm-2">
                <label class="mt-1">Wenn:</label>
              </div>
              <div class="col-sm-10">
                <input v-model="newIdea.hypothesis.ifTerm" class="form-control mb-2" placeholder="Wir X ändern/hinzufügen/löschen/etc..." type="text">
              </div>
              <div class="col-sm-2">
                <label class="mt-1">Dann:</label>
              </div>
              <div class="col-sm-10">
                <input v-model="newIdea.hypothesis.thenTerm" class="form-control mb-2" placeholder="machen mehr/weniger Nutzer Y..." type="text">
              </div>
              <div class="col-sm-2">
                <label class="mt-1">Weil:</label>
              </div>
              <div class="col-sm-10">
                <input v-model="newIdea.hypothesis.becauseTerm" class="form-control mb-2" placeholder="sie Z besser verstehen/erreichen/etc..." type="text">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row justify-content-center">
          <div class="col-sm-6">
            <button class="btn btn-secondary btn-block" @click="step--">Schritt zurück</button>
          </div>
          <div class="col-sm-6">
            <button class="btn btn-primary btn-block" @click="saveIdea">Idee speichern</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import NodeSelector from "@/components/NodeSelector";
export default {
  name: 'IdeasEdit',
  components: {NodeSelector},
  data() {
    return {
      step: 0,
      search: "",
      newIdea: {
        id: "",
        title: "",
        description: "",
        images: [],
        nodeUrl: "",
        slotId: "",
        theoryId: this.activeTheoryId,
        findingIds: [],
        createdAt: "",
        lead: "",
        hypothesis: {
          ifTerm: "",
          thenTerm: "",
          becauseTerm: "",
        },
        userProblem: "",
        patterns: [],
        pagetype: [],
        device: [],
        level: [],
        goal: "",
        scores: {
          frontendEffort: 3,
          backendEffort: 3,
          conceptEffort: 3,
          otherEffort: 3,
          visualContrast: 3,
          behaviourContrast: 3,
          patternsImpact: 3,
          trafficImpact: 3
        },
        searchTags: [],
      }
    }
  },
  computed: {
    nodes() {
      let searchNode = node => {
        let isSearched = this.search === "";
        if (!isSearched) {
          for (let prop of ['url', 'title']) {
            if (node[prop].indexOf(this.search) >= 0) {
              isSearched = true;
            }
          }
        }
        return isSearched;
      }
      return [{
        id: this.generateId(),
        url: "Platzhalter",
        title: "Leerer Schritt (füge hier eigene Seiten hinzu)",
        image: {
          thumb: "",
          desktop: "",
          tablet: "",
          mobile: ""
        }
      }]
          .concat(this.project.netNodes.filter(searchNode))
          .concat(this.project.externalNodes.filter(searchNode));
    },
    findings() {
      return this.project.findings.filter(finding => this.newIdea.findingIds.indexOf(finding.id) >= 0);
    },
    newFindings() {
      return this.project.findings.filter(finding => !this.newIdea.findingIds.indexOf(finding.id) >= 0);
    }
  },
  methods: {
    addFinding($event) {
      let id = $event.target.value;
      this.newIdea.findingIds.push(id);
      $event = $event.target.value = "";
    },
    removeFinding(index) {
      this.newIdea.findingIds.splice(index, 1);
    },
    uploadImage($event) {
      console.debug($event);
    },
    saveIdea() {
      if (this.$route.params.id) {
        let index = this.project.ideas.findIndex(idea => idea.id === this.newIdea.id);
        this.project.ideas.splice(index, 1, this.newIdea);
      } else {
        this.newIdea.createdAt = new Date().toISOString();
        this.project.ideas.push(this.newIdea);
      }
      this.$store.dispatch('project/updateProjectByProp', {prop: 'ideas', data: this.project.ideas})
          .then(res => {
            console.debug(res);
            this.$router.push("/ideas");
          })
          .catch(err => {
            console.error(err);
          });
    }
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.newIdea = this.project.ideas.find(idea => idea.id === this.$route.params.id);
    } else {
      this.newIdea.id = this.generateId();
    }
  }
}
</script>
